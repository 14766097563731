const sideMenu3 = [
    { label: 'Dashboard', url: '/dashboard-admin', icon: 'fi fi-rr-dashboard' },
    { label: 'Riwayat Kiriman', url: '/riwayat/admin/all', icon: 'fi fi-rr-time-past' },
    { label: 'SLA Monitoring', url: '/sla-monitoring', icon: 'fi fi-rr-document' },
    { label: 'Cek Status', url: '/cek', icon: 'fi fi-rr-search-location' },
    { label: 'Profil', url: '/profile', icon: 'fi fi-rr-user' },
    {
        label: 'Data Shipper',
        url: '#',
        icon: 'fi fi-rr-folder',
        menus: [
            { label: 'Shipper', url: '/shipper', icon: 'fi fi-rr-circle' },
            { label: 'Shipper CS', url: '/settings/user', icon: 'fi fi-rr-users-alt' },
            { label: 'Kiriman', url: '/shipper/recap', icon: 'fi fi-rr-circle' },
            { label: 'Top-Up', url: '/saldo/topup/approval', icon: 'fi fi-rr-circle' },
            { label: 'Withdraw', url: '/saldo/withdraw/approval', icon: 'fi fi-rr-circle' },
            { label: 'Rekap Saldo', url: '/saldo/shipper', icon: 'fi fi-rr-circle' },
        ]
    },
    //{ label: 'Buyer', url: '/buyer', icon: 'fi fi-rr-users' },

    {
        label: 'Tiket & Klaim',
        url: '#',
        icon: 'fi fi-rr-ticket',
        menus: [{
                label: 'Buat Tiket Baru',
                url: () => {
                    //this.$root.$emit('createTicket', 3);
                },
                icon: 'fi fi-rr-circle'
            },
            { label: 'Daftar Tiket', url: '/settings/tickets/all', icon: 'fi fi-rr-circle' },
            { label: 'Daftar Klaim', url: '/settings/tickets/claim', icon: 'fi fi-rr-circle' },
        ]
    },

    {
        label: 'Arsip',
        url: '#',
        icon: 'fi fi-rr-box',
        menus: [
            { label: 'Arsip Riwayat Kiriman', url: '/arsip/riwayat', icon: 'fi fi-rr-circle' },
            { label: 'Arsip Daftar Ticket', url: '/arsip/ticket', icon: 'fi fi-rr-circle' },
            { label: 'Arsip Daftar Klaim', url: '/arsip/claim', icon: 'fi fi-rr-circle' },
        ]
    },

    {
        label: 'PPOB',
        url: '#',
        icon: 'fi fi-rr-money-transfer-smartphone',
        menus: [
            { label: 'Transaksi PPOB', url: '/ppob/history', icon: 'fi fi-rr-circle' },
            { label: 'Produk PPOB', url: '/ppob/products', icon: 'fi fi-rr-circle' },
            { label: 'Upload Rekon PPOB Winpay', url: '/ppob/rekonsiliasi/winpay', icon: 'fi fi-rr-circle' },
            { label: 'Laporan Subsidiary Ledger akun Uang Muka', url: '/ppob/subsidiary-ledger', icon: 'fi fi-rr-circle' },
        ]
    },

    {
        label: 'Update Tracking',
        url: '#',
        icon: 'fi fi-rr-rotate-square',
        menus: [
            { label: 'Cek Tracking <br> Kurir', url: '/tracking', icon: 'fi fi-rr-circle' },
        ]
    }

];
export default sideMenu3;